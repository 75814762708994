// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  orgId: 'cems',
  appBaseUrl: '',
  parrotApiBaseUrl: 'https://hub-staging.storyshaper.io/parrot_subscription', // if run using 'start dotnet watch run'
  apiBaseUrl: 'https://hub-staging.storyshaper.io/mif_subscription',
  authServerUrl: 'https://hub-staging.storyshaper.io/green',
  clientSecret: 'd7a3ddf0-2253-403f-bed8-720ec119e38e',
  clientId: 'mif_subscription',
  enableABTesting: false,
  useABTestingStagingURL: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
